
import Moderator from '@/models/bread/Moderator/institutionSponsorship'
export default {
  key:'Institution',
  name:{
    singular:'Institution',
    plural:'Institutions',
  },
  parents:[
    'SponsorSponsorship',
  ],
  grandParents:[
    'InstitutionSponsor',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}