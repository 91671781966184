import i18n from "@/plugins/i18n";
import moment from "moment";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: "Institution",
        },
        {
          text: "Seat",
          value: "seat",
        },
        {
          text: "Start At",
          value: "timestampStart",
        },
        {
          text: "End At",
          value: "timestampEnd",
        },
      ],
    },
  },
  read: {
    tabs: [
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
      // {
      //   key:'Activity',
      //   label:i18n.t("model.name.activity"),
      // },
      // {
      //   key:'Performance',
      //   label:i18n.t("model.name.performance"),
      // },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: "Institution",
        },
        {
          text: "Seat",
          value: "seat",
        },
        {
          text: "Start At",
          value: "timestampStart",
        },
        {
          text: "End At",
          value: "timestampEnd",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: "model",
        key: "institutionId",
        name: "Institution",
        model: "institution",
        optionText: "name",
        server: "sso",
        optionValue: "id",
      },
      {
        type: "number",
        key: "seat",
        name: "Seat",
        min: 1,
        defaultValue: 1,
      },
      {
        type: "date",
        key: "dateStart",
        name: "Date Start",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: moment().format("YYYY-MM-DD"),
      },
      {
        type: "time",
        key: "timeStart",
        name: "Time Start",
        defaultValue: "08:00",
      },
      {
        type: "date",
        key: "dateEnd",
        name: "Date End",
        min: moment().format("YYYY-MM-DD"),
        defaultValue: moment().add(5, "d").format("YYYY-MM-DD"),
      },
      {
        type: "time",
        key: "timeEnd",
        name: "Time End",
        defaultValue: "23:00",
      },
    ],
    rules: {
      seat: [(v) => !!v || 'Seat count is required'],
    },
  },
  edit: true,
  delete: true,
};
